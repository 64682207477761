import React, { useContext } from "react";
import { StoreContext } from "../context";
import {
  mainBlue,
  mainOrange,
  mainGrey,
  transHover,
  screen,
} from "../components/common/variables";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageTitle from "../components/page-title";
import FeaturedProducts from "../components/featured-products";
import FlipMove from "react-flip-move";
import Form from "../components/form";

const Wrapper = styled.div`
  padding-bottom: 110px;
  padding-top: 73px;
  @media ${screen.xsmall} {
    padding-bottom: ${(props) => (props.hasOrder ? `170px` : `270px`)};
    padding-top: 87px;
  }
  @media ${screen.small} {
    padding-top: 80px;
  }
  @media ${screen.xlarge} {
    padding-bottom: ${(props) => (props.hasOrder ? `200px` : `300px`)};
  }

  .content {
    padding-top: 35px;
    width: 100%;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 80px;
    @media ${screen.xsmall} {
      padding-top: 40px;
      padding-left: 70px;
      padding-right: 70px;
    }
    @media ${screen.small} {
      padding-top: 82px;
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.medium} {
      padding-top: 100px;
      padding-left: 160px;
      padding-right: 160px;
    }
    @media ${screen.xlarge} {
      padding-bottom: 140px;
      padding-top: 140px;
      padding-left: 280px;
      padding-right: 280px;
    }

    &__title {
      margin-bottom: 20px;
      @media ${screen.xlarge} {
        margin-bottom: 25px;
      }
    }

    &__description {
      color: ${mainBlue};
      max-width: 730px;

      p {
        font-size: 1.08rem;
        margin-bottom: 18px;
        @media ${screen.xsmall} {
          font-size: 1.08rem;
          margin-bottom: 22px;
        }
        @media ${screen.xlarge} {
          margin-bottom: 30px;
          font-size: 1.25rem;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__form-container {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      flex-direction: column;
      @media ${screen.small} {
        flex-direction: row;
        margin-top: 70px;
      }
      @media ${screen.large} {
        margin-top: 80px;
      }

      .product-list {
        max-width: 630px;
        width: 100%;

        .list-wrapper {
          margin: 0 0 50px 0;
          @media ${screen.small} {
            margin: 0 0 100px 0;
          }
          &:last-child {
            background: red;
          }
        }

        &__heading {
          color: ${mainGrey};
          font-size: 1.07rem;
          font-weight: 800;
          margin-bottom: 20px;
          @media ${screen.xsmall} {
            font-size: 1.12rem;
            margin-bottom: 30px;
          }
        }

        &__labels {
          border-bottom: 2px ${mainGrey} solid;
          display: flex;
          padding-bottom: 8px;
          justify-content: space-between;
          margin: 0 0 20px 0;
          @media ${screen.xsmall} {
            margin: 0 0 30px 0;
          }

          .label {
            color: ${mainGrey};
            display: block;
            font-size: 1.06rem;
            @media ${screen.xsmall} {
              font-size: 1.3rem;
            }
            @media ${screen.xlarge} {
              font-size: 1.5rem;
            }

            &--type-name {
              max-width: 164px;
              width: 100%;
              line-height: 1.4;
              @media ${screen.small} {
                max-width: 310px;
              }
              @media ${screen.medium} {
                max-width: 310px;
              }
              @media ${screen.large} {
                max-width: 390px;
              }
              @media ${screen.xlarge} {
                max-width: 432px;
              }
            }

            &--qty {
              max-width: 46px;
              width: 100%;
              @media ${screen.xsmall} {
                max-width: 60px;
              }
            }

            &--hidden {
              visibility: hidden;
            }
          }
        }

        &__foundry-list,
        &__lance-list {
          .product-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            @media ${screen.small} {
              margin-top: 16px;
            }

            &__name {
              color: ${mainBlue};
              display: block;
              font-size: 0.95rem;
              font-weight: 600;
              max-width: 164px;
              width: 100%;
              line-height: 1.4;
              @media ${screen.xsmall} {
                max-width: 432px;
                font-size: 1.15rem;
              }
              @media ${screen.small} {
                max-width: 310px;
              }
              @media ${screen.medium} {
                max-width: 310px;
              }
              @media ${screen.large} {
                max-width: 390px;
              }
              @media ${screen.xlarge} {
                max-width: 432px;
                font-size: 1.25rem;
              }
            }

            &__qty {
              display: block;
              max-width: 46px;
              width: 100%;
              @media ${screen.xsmall} {
                max-width: 60px;
              }
              input {
                width: 100%;
                text-align: center;
                border: 1px ${mainBlue} solid;
                padding: 2px 0;
                color: ${mainBlue};
                outline-color: ${mainBlue};
                font-size: 1rem;
                @media ${screen.xsmall} {
                  padding: 4px 0;
                }
                @media ${screen.small} {
                  padding: 7px 0;
                }
              }
            }

            &__remove {
              display: block;
              color: ${mainGrey};
              cursor: pointer;
              font-size: 0.87rem;
              transition: ${transHover};
              @media ${screen.xsmall} {
                font-size: 1.02rem;
              }
              @media ${screen.xlarge} {
                font-size: 1.12rem;
              }
              &:hover {
                color: ${mainOrange};
              }
            }
          }
        }

        &__edit-btn {
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          margin-top: 58px;
          @media ${screen.xsmall} {
            margin-top: 75px;
          }
          span {
            color: ${mainGrey};
            cursor: pointer;
            font-size: 1.12rem;
            text-decoration: underline;
            transition: ${transHover};
            display: block;

            &:hover {
              color: ${mainOrange};
            }
          }

          p {
            color: ${mainOrange};
            font-size: 1.17rem;
            @media ${screen.xsmall} {
              font-size: 1.25rem;
              margin-top: 20px;
            }
          }
        }
      }

      .form-wrapper {
        width: 100%;
        transition: ${transHover};
        opacity: ${(props) => (props.isAllQtyFilled ? 1 : 0.4)};
        pointer-events: ${(props) => (props.isAllQtyFilled ? `all` : `none`)};
        margin-top: 60px;
        @media ${screen.small} {
          margin-top: 0;
          margin-left: 100px;
          max-width: 600px;
        }
      }
    }
  }
`;

const EnquiryPage = () => {
  const { orders, updateOrder, removeOrder } = useContext(StoreContext);

  const hasOrder = orders.length ? true : false;

  const lanceProducts = orders.filter((product) => product.type === "lance");
  const foundryProducts = orders.filter(
    (product) => product.type === "foundry"
  );
  const contessiProducts = orders.filter(
    (product) => product.type === "contessi"
  );

  const isAllQtyFilled = orders.every((product) => product.quantity);

  return (
    <Layout>
      <SEO title={`Enquiry | Australian Thermic Lance Company`} />
      <Wrapper
        hasOrder={hasOrder}
        isAllQtyFilled={isAllQtyFilled}
        lanceMarginTop={foundryProducts.length > 0}
      >
        <div className="content">
          <PageTitle className="content__title">Product Enquiry</PageTitle>
          {hasOrder ? (
            <div className="content__description">
              <p>
                Please fill out the quantity for each product you have added to
                receive a comprehensive quote and the best distributor to
                contact.
              </p>
            </div>
          ) : (
            <div className="content__description">
              <p>
                <strong>Your enquiry is currently empty. </strong>
              </p>
              <p>
                Add products to receive a comprehensive price quote and
                distributor information for your convenience.
              </p>
            </div>
          )}

          {hasOrder && (
            <div className="content__form-container">
              <div className="product-list">
                <h5 className="product-list__heading">ITEMS</h5>

                {foundryProducts.length > 0 && (
                  <div className="list-wrapper">
                    <div className="product-list__labels">
                      <span className="label label--type-name">
                        Foundry Products
                      </span>
                      <span className="label label--qty">QTY*</span>
                      <span className="label label--hidden">Hide</span>
                    </div>

                    <ul className="product-list__lance-list">
                      <FlipMove enterAnimation={`fade`} leaveAnimation={`fade`}>
                        {foundryProducts.map((product) => (
                          <li className="product-item" key={product.code}>
                            <span className="product-item__name">
                              {product.name} - {product.code}
                            </span>
                            <span className="product-item__qty">
                              <input
                                type="text"
                                onChange={(e) =>
                                  updateOrder(product.code, e.target.value)
                                }
                                value={product.quantity}
                              />
                            </span>
                            <span
                              className="product-item__remove"
                              onClick={() => removeOrder(product.code)}
                            >
                              Remove
                            </span>
                          </li>
                        ))}
                      </FlipMove>
                    </ul>
                  </div>
                )}
                {contessiProducts.length > 0 && (
                  <div className="list-wrapper">
                    <div className="product-list__labels product-list__labels--lance">
                      <span className="label label--type-name">
                        Contessi Products
                      </span>
                      <span className="label label--qty">QTY*</span>
                      <span className="label label--hidden">Hide</span>
                    </div>
                    <ul className="product-list__foundry-list">
                      <FlipMove enterAnimation={`fade`} leaveAnimation={`fade`}>
                        {contessiProducts.map((product) => (
                          <li className="product-item" key={product.code}>
                            <span className="product-item__name">
                              {product.name} - {product.code}
                            </span>
                            <span className="product-item__qty">
                              <input
                                type="text"
                                onChange={(e) =>
                                  updateOrder(product.code, e.target.value)
                                }
                                value={product.quantity}
                              />
                            </span>
                            <span
                              className="product-item__remove"
                              onClick={() => removeOrder(product.code)}
                            >
                              Remove
                            </span>
                          </li>
                        ))}
                      </FlipMove>
                    </ul>
                  </div>
                )}

                {lanceProducts.length > 0 && (
                  <div className="list-wrapper">
                    <div className="product-list__labels product-list__labels--lance">
                      <span className="label label--type-name">
                        Thermic Lance Products
                      </span>
                      <span className="label label--qty">QTY*</span>
                      <span className="label label--hidden">Hide</span>
                    </div>
                    <ul className="product-list__foundry-list">
                      <FlipMove enterAnimation={`fade`} leaveAnimation={`fade`}>
                        {lanceProducts.map((product) => (
                          <li className="product-item" key={product.code}>
                            <span className="product-item__name">
                              {product.name} - {product.code}
                            </span>
                            <span className="product-item__qty">
                              <input
                                type="text"
                                onChange={(e) =>
                                  updateOrder(product.code, e.target.value)
                                }
                                value={product.quantity}
                              />
                            </span>
                            <span
                              className="product-item__remove"
                              onClick={() => removeOrder(product.code)}
                            >
                              Remove
                            </span>
                          </li>
                        ))}
                      </FlipMove>
                    </ul>
                  </div>
                )}

                <div className="product-list__edit-btn">
                  {/* <span>EDIT ORDER ITEMS</span> */}
                  {!isAllQtyFilled && <p>Quantity can't be empty</p>}
                </div>
              </div>

              <div className="form-wrapper">
                <Form enquiry orders={orders} />
              </div>
            </div>
          )}
        </div>
        {!hasOrder && (
          <FeaturedProducts heading={`Add products to your quote`} />
        )}
      </Wrapper>
    </Layout>
  );
};

export default EnquiryPage;
