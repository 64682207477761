// → featured products to be reused for a row section on a page

import React from "react";
import { navigate, graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { mainBlue, lightGrey, transHover, screen } from "../common/variables";
import LabeledArrow from "../../components/labeled-arrow";
import { GatsbyImage } from "gatsby-plugin-image";

const Wrapper = styled.section`
  .products {
    padding-left: 35px;
    padding-right: 35px;

    @media ${screen.xsmall} {
      padding-left: 70px;
      padding-right: 70px;
    }
    @media ${screen.small} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.medium} {
      padding-left: 65px;
      padding-right: 65px;
      padding-left: 160px;
      padding-right: 160px;
    }
    @media ${screen.large} {
    }
    @media ${screen.xlarge} {
      padding-left: 280px;
      padding-right: 280px;
    }

    &__heading {
      color: ${mainBlue};
      font-weight: 800;
      font-size: 1.7rem;
      margin-bottom: 68px;
      @media ${screen.xsmall} {
        font-size: 2.2rem;
        margin-bottom: 85px;
      }
      @media ${screen.small} {
        margin-bottom: 95px;
      }
      @media ${screen.xlarge} {
        font-size: 2.5rem;
      }
    }

    &__list {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      @media ${screen.xsmall} {
        flex-direction: row;
        margin: 0 0 0 -50px;
      }
      @media ${screen.small} {
        padding: 0 50px;
        margin: 50px -50px 0 -50px;
      }
      @media ${screen.xlarge} {
        padding: 0;
      }

      .each-product {
        width: 100%;
        position: relative;
        margin: 0 0 120px 0;
        cursor: pointer;
        max-width: 410px;
        @media ${screen.xsmall} {
          margin: 0 50px;
        }
        @media ${screen.small} {
        }
        @media ${screen.xlarge} {
          max-width: 480px;
          margin: 0 100px;
        }

        &__img {
          max-width: 480px;
          position: relative;
          z-index: 2;
          transition: ${transHover};
        }

        &__name {
          color: ${mainBlue};
          font-size: 1.7rem;
          position: relative;
          z-index: 2;
          margin-top: 14px;
          @media ${screen.xsmall} {
            font-size: 2.05rem;
            margin-top: 23px;
          }
          @media ${screen.small} {
            font-size: 2.25rem;
          }
          @media ${screen.xlarge} {
            font-size: 2.4rem;
            margin-top: 28px;
          }
        }

        &__link {
          position: relative;
          z-index: 2;
          margin-top: 5px;
          transition: ${transHover};

          svg {
            width: 62px;
            margin-left: 12px;
            @media ${screen.xsmall} {
              width: 72px;
              margin-left: 15px;
            }
          }
        }

        &__bg-box {
          background-color: ${lightGrey};
          width: 100%;
          height: calc(100% + 68px);
          position: absolute;
          top: -40px;
          left: 35px;
          z-index: 1;
          transition: ${transHover};
          @media ${screen.xsmall} {
            left: 50px;
            max-width: 470px;
            height: calc(100% + 68px);
          }
          @media ${screen.small} {
            max-width: 470px;
          }
        }

        @media ${screen.withCursor} {
          &:hover .each-product__bg-box {
            transform: scaleY(0.995) translateY(3px);
          }
          &:hover .each-product__link {
            transform: translateX(2px);
          }
          &:hover .each-product__img {
            transform: scale(0.995) translate(1px, 1px);
          }
        }
      }
    }

    .contessi-product {
      display: flex;
      margin: -40px 0 90px 0;
      cursor: pointer;
      padding: 36px 0;
      width: 100%;
      flex-direction: column;
      position: relative;
      @media ${screen.xsmall} {
        flex-direction: row;
        margin: 110px 0 0 0;
      }
      @media ${screen.small} {
        margin: 110px 0 0 50px;
      }

      &__img {
        width: 100%;
        max-width: 480px;
        z-index: 2;
        transition: ${transHover};
      }

      &__text {
        color: ${mainBlue};

        z-index: 2;
        @media ${screen.xsmall} {
          margin: 0 0 0 calc(20px + 1vw);
        }
        @media ${screen.large} {
          margin: 0 0 0 calc(20px + 3vw);
        }

        .heading {
          color: ${mainBlue};
          font-size: 1.7rem;
          position: relative;
          z-index: 2;
          margin-top: 14px;
          max-width: 480px;
          @media ${screen.xsmall} {
            font-size: 2.05rem;
            margin-top: 23px;
          }
          @media ${screen.small} {
            font-size: 2.25rem;
          }
          @media ${screen.large} {
            max-width: none;
          }
          @media ${screen.xlarge} {
            font-size: 2.4rem;
            margin-top: 28px;
          }
        }

        .description {
          margin: 6px 0 0 0;
          max-width: 480px;
          @media ${screen.large} {
            max-width: 510px;
          }

          p {
            font-weight: 300;
            font-size: 1.17rem;
            margin-bottom: 22px;
            @media ${screen.xsmall} {
              font-size: 1.3rem;
            }
            @media ${screen.medium} {
              font-size: 1.4rem;
            }
            @media ${screen.xlarge} {
              font-size: 1.5rem;
              margin-bottom: 30px;
            }
          }
        }
      }

      &::after {
        content: "";
        background: ${lightGrey};
        width: 100%;
        height: 100%;
        top: 50%;
        left: 35px;
        transform: translate(0, -50%);
        z-index: 1;
        position: absolute;
        transition: ${transHover};
        @media ${screen.xsmall} {
          transform: translate(-50%, -50%);
          left: 50%;
          width: 100%;
          margin: 0 40px 0 40px;
        }
        @media ${screen.medium} {
          width: 92%;
          margin: 0;
        }
        @media ${screen.large} {
          width: 90%;
        }
      }

      &:hover .contessi-product__img {
        transform: scale(0.995) translate(1px, 1px);
      }

      &:hover ::after {
        transform: translate(-50%, -49%);
      }

      &:hover .each-product__link {
        transform: translateX(2px);
      }
    }
  }
`;

const FeaturedProducts = ({ heading }) => {
  // → pulled data from CMS
  const data = useStaticQuery(dataQuery);

  return (
    <Wrapper>
      <section className="products">
        <h2 className="products__heading">{heading ? heading : `Heading`}</h2>
        <ul className="products__list">
          <li
            className="each-product"
            onClick={() => navigate("/lance-products")}
          >
            <div className="each-product__img">
              {data.thermic.data.thumbnail_image.gatsbyImageData && (
                <GatsbyImage
                  image={data.thermic.data.thumbnail_image.gatsbyImageData}
                  alt={data.thermic.data.thumbnail_image.alt || "Product"}
                />
              )}
            </div>
            <h2 className="each-product__name">Thermic Lance</h2>
            <LabeledArrow
              className="each-product__link"
              label={`SEE PRODUCTS`}
              url={`/lance-products`}
            />
            <div className="each-product__bg-box"></div>
          </li>

          <li
            className="each-product"
            onClick={() => navigate("/foundry-products")}
          >
            <div className="each-product__img">
              {data.thermic.data.thumbnail_image.gatsbyImageData && (
                <GatsbyImage
                  image={data.foundry.data.thumbnail_image.gatsbyImageData}
                  alt={data.foundry.data.thumbnail_image.alt || "Product"}
                />
              )}
            </div>
            <h2 className="each-product__name">Foundry Products</h2>
            <LabeledArrow
              className="each-product__link"
              label={`SEE PRODUCTS`}
              url={`/foundry-products`}
            />
            <div className="each-product__bg-box"></div>
          </li>
        </ul>

        <div
          className="contessi-product"
          onClick={() => navigate("/contessi-products/")}
        >
          <div className="contessi-product__img">
            <GatsbyImage
              image={data.contessi.data.thumbnail_image.gatsbyImageData}
              alt={data.contessi.data.thumbnail_image.alt || "Product"}
            />
          </div>

          <div className="contessi-product__text">
            <h2 className="heading">Authorised CONTESSI Distributor</h2>

            <div className="description">
              <p>
                ATLC proudly distributes the CONTESSI Brand for larger size
                handles and equipment.
              </p>
            </div>
            <LabeledArrow
              className="each-product__link"
              label={`SEE PRODUCTS`}
              url={`/contessi-products/`}
            />
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default FeaturedProducts;

export const dataQuery = graphql`
  {
    thermic: prismicWhatsThermicLancePage {
      data {
        thumbnail_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    foundry: prismicFoundryProductsPage {
      data {
        thumbnail_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    contessi: prismicWhatsContessiPage {
      data {
        thumbnail_image {
          alt

          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`;
